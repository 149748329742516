import React from "react";
import { useStyles } from "./PosterCarousel.styles";
import {
  CarouselProvider,
  ButtonBack,
  ImageWithZoom,
  ButtonNext,
  Slider,
  Slide,
  DotGroup
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { IPoster } from "../../Interfaces/interfaces";

interface Props {
  posters: IPoster[];
}

export const PosterCarousel: React.FC<Props> = props => {
  const classes = useStyles();
  const { posters } = props;
  return (
    <CarouselProvider
      naturalSlideWidth={1}
      naturalSlideHeight={1}
      totalSlides={posters.length}
      className={classes.gridContainer}
    >
      {posters.length > 1 ? (
        <ButtonBack className={classes.backButton}>Back</ButtonBack>
      ) : null}

      <Slider className={classes.gridItem}>
        {posters.map((poster, index) => {
          return (
            <Slide index={index} key={index}>
              <div className={classes.cover}>
                <ImageWithZoom key={index} src={poster.mediumUrl} />
              </div>
            </Slide>
          );
        })}
      </Slider>
      {posters.length > 1 ? (
        <ButtonNext className={classes.nextButton}>Next</ButtonNext>
      ) : null}
      {posters.length > 1 ? <DotGroup className={classes.dotGroup} /> : null}
    </CarouselProvider>
  );
};
