import React from "react";
import { PosterDetail, PosterImage } from ".";
import { posters } from "../../Constants";

interface Props {
  match?: any;
}

export const PosterPage: React.FC<Props> = props => {
  const poster = posters.find(poster => {
    return poster.name === props.match.params.id;
  });
  return (
    <>
      <PosterImage poster={poster} />
      <PosterDetail poster={poster} />
    </>
  );
};
