import React from "react";
import { AboutDetail, AboutImage } from ".";

interface Props {}

export const AboutPage: React.FC<Props> = () => {
  return (
    <>
      <AboutImage />
      <AboutDetail />
    </>
  );
};
