import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { styleConstant } from "../../Constants";

export const useStyles = makeStyles((theme: Theme) => {
  const { siteNavigationComponentHeight } = styleConstant;
  return createStyles({
    site_navigation_bar_root: {
      height: siteNavigationComponentHeight,
      gridColumnStart: "center",
      gridRowStart: "top",
      zIndex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "left",
      paddingLeft: "35px"
    },
    options: {
      display: "flex",
      fontSize: "0.9rem"
    },
    option: { padding: "15px" },
    dot: {
      height: "7px",
      width: "7px",
      backgroundColor: "black",
      borderRadius: "50%",
      marginTop: "20px"
    },
    tooltip: {
      backgroundColor: "pink"
    }
  });
});
