import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { styleConstant } from "../../Constants";

export const useStyles = makeStyles((theme: Theme) => {
  const { siteNavigationComponentHeight } = styleConstant;

  return createStyles({
    posterDetail_root: {
      gridColumnStart: "center",
      gridRowStart: "top",
      gridRowEnd: "bottom",
      zIndex: 0,
      paddingTop: siteNavigationComponentHeight,
      height: "100%",
      paddingLeft: "50px"
    },
    content: {},
    display_linespace: {
      whiteSpace: "pre-line"
    }
  });
});
