import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    thumbContainer: {
      display: "flex",
      flexWrap: "wrap"
    },
    thumbs: {
      margin: "10px",
      width: "25%"
    }
  });
});
