import React from "react";
import { useStyles } from "./HomePageImage.styles";
import { posters } from "../../Constants";
import { PosterCarousel } from "../../Components";
interface Props {}

export const HomePageImage: React.FC<Props> = () => {
  const classes = useStyles();
  return (
    <div className={classes.homePageImage_root}>
      <PosterCarousel posters={posters} />
    </div>
  );
};
