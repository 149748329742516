import React from "react";
import dompurify from "dompurify";

import { useStyles } from "./PosterDetail.styles";
import { PosterDetailFooter } from "../../Components";

interface Props {
  poster?: any;
}

export const PosterDetail: React.FC<Props> = props => {
  const sanitizer = dompurify.sanitize;
  const classes = useStyles();
  return (
    <div className={classes.posterDetail_root}>
      <div>The {props.poster.title} Print Poster</div>
      <div>{props.poster.subTitle}</div>
      <div>ITEM DESCRIPTION</div>
      <div
        className={classes.display_linespace}
        dangerouslySetInnerHTML={{
          __html: sanitizer(props.poster.description)
        }}
      />
      <PosterDetailFooter />
    </div>
  );
};
