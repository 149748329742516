import React from "react";
import { HomePageDetail, HomePageImage } from ".";

interface Props {}

export const HomePage: React.FC<Props> = () => {
  return (
    <>
      <HomePageImage /> <HomePageDetail />
    </>
  );
};
