import React from "react";
import { ContactDetail, ContactImage } from ".";

interface Props {}

export const ContactPage: React.FC<Props> = () => {
  return (
    <>
      <ContactImage />
      <ContactDetail />
    </>
  );
};
