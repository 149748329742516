import React from "react";
import { sections } from "../../Constants";
import { useStyles } from "./SectionImage.styles";
import { withRouter } from "react-router";
import { PosterCarousel } from "../../Components";
interface Props {
  match?: any;
}

export const SectionImageComponent: React.FC<Props> = ({ match }) => {
  const classes = useStyles();
  const section = sections.find(section => {
    return section.name === match.params.section;
  }) || { title: "", description: "" };
  const posters = sections
    .filter(section => {
      return section.name === match.params.section;
    })
    .flatMap(section => {
      return section.posters;
    });
  return (
    <div className={classes.sectionImage_root}>
      <PosterCarousel posters={posters} />
    </div>
  );
};

export const SectionImage = withRouter(SectionImageComponent);
