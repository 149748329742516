import React from "react";
import { useStyles } from "./PosterDetailFooter.styles";

interface Props {}

export const PosterDetailFooter: React.FC<Props> = () => {
  const classes = useStyles();

  return (
    <div className={classes.posterDetailFooterRoot}>
      {`The illustration is printed using good quality fade resistant inks on smooth matt printer card. 
      
      The frame is NOT included as the postal cost would be too high. All prints are made to order, however I will dispatch your order as soon as possible. 
      
      If you have any questions, get in touch.

      Many Thanks!`}
    </div>
  );
};
