import React from "react";
import { useStyles } from "./Title.styles";

interface Props {}

export const Title: React.FC<Props> = () => {
  const classes = useStyles();

  return (
    <div className={classes.titleContainer}>
      <div className={classes.title}>
        <h1>CLANNAH&nbsp;CARDS&nbsp;&&nbsp;PRINTS</h1>
      </div>
    </div>
  );
};
