import React from "react";
import { useStyles } from "./SectionDetail.styles";
import { withRouter } from "react-router";
import { sections } from "../../Constants";
import { ThumbsContainer } from "../../Components";

interface Props {
  match?: any;
}

const SectionDetailComponent: React.FC<Props> = ({ match }) => {
  const classes = useStyles();
  const section = sections.find(section => {
    return section.name === match.params.section;
  }) || { title: "", description: "" };
  const posters = sections
    .filter(section => {
      return section.name === match.params.section;
    })
    .flatMap(section => {
      return section.posters;
    });

  return (
    <div className={classes.sectionDetail_root}>
      <div className={classes.title}>{section.title}</div>
      <ThumbsContainer thumbs={posters} />
      <div className={classes.description}>{section.description}</div>
    </div>
  );
};

export const SectionDetail = withRouter(SectionDetailComponent);
