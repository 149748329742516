/*
Image format
This allows quick swap of format used for various images (sectionThumb prints etc)
*/

const sectionThumb = ".jpg";
const posterThumb = ".jpg";
// const posterSmall = ".jpg";
const posterMedium = ".jpg";
// const posterLarge = ".jpg";

/*
Posters

When adding new posters:

1. create new poster as seen in following constants
2. add new poster object to posters list
3. add new poster object to suitable section(s)

sections are defined lower down.
*/

const alderleyEdge = {
  name: "alderleyEdge",
  title: "Alderley Edge",
  mediumUrl: "/images/posters/alderleyEdge" + posterMedium,
  thumbUrl: "/images/posters/alderleyEdge-thumb" + posterThumb,
  linkUrl: "/poster/alderleyEdge",
  description: "This is <b>Alderley Edge</b>"
};
const lymeCage = {
  name: "lymeCage",
  title: "Lyme Cage",
  mediumUrl: "/images/posters/lymeCage" + posterMedium,
  thumbUrl: "/images/posters/lymeCage-thumb" + posterThumb,
  linkUrl: "/poster/lymeCage",
  description:
    "<b>Lyme Cage</b> First two words should be in bold.\n This should be on a new line."
};
const mowCop = {
  name: "mowCop",
  title: "Mow Cop",
  mediumUrl: "/images/posters/mowCop" + posterMedium,
  thumbUrl: "/images/posters/mowCop-thumb" + posterThumb,
  linkUrl: "/poster/mowCop",
  description: "no description"
};
const jodrellBank = {
  name: "jodrellBank",
  title: "Jodrell Bank",
  mediumUrl: "/images/posters/jodrellBank" + posterMedium,
  thumbUrl: "/images/posters/jodrellBank-thumb" + posterThumb,
  linkUrl: "/poster/jodrellBank",
  description: "no description"
};
const beestonCastle = {
  name: "beestonCastle",
  title: "Beeston Castle",
  mediumUrl: "/images/posters/beestonCastle" + posterMedium,
  thumbUrl: "/images/posters/beestonCastle-thumb" + posterThumb,
  linkUrl: "/poster/beestonCastle",
  description: "no description"
};
const twemlowViaduct = {
  name: "twemlowViaduct",
  title: "Twemlow Viaduct",
  mediumUrl: "/images/posters/twemlowViaduct" + posterMedium,
  thumbUrl: "/images/posters/twemlowViaduct-thumb" + posterThumb,
  linkUrl: "/poster/twemlowViaduct",
  description: "no description"
};
const sandbach = {
  name: "sandbach",
  title: "Sandbach",
  mediumUrl: "/images/posters/sandbach" + posterMedium,
  thumbUrl: "/images/posters/sandbach-thumb" + posterThumb,
  linkUrl: "/poster/sandbach",
  description: "no description"
};
const holmesChapel = {
  name: "holmesChapel",
  title: "Holmes Chapel",
  mediumUrl: "/images/posters/holmesChapel" + posterMedium,
  thumbUrl: "/images/posters/holmesChapel-thumb" + posterThumb,
  linkUrl: "/poster/holmesChapel",
  description: "no description"
};
const bakewell = {
  name: "bakewell",
  title: "Bakewell ",
  mediumUrl: "/images/posters/bakewell" + posterMedium,
  thumbUrl: "/images/posters/bakewell-thumb" + posterThumb,
  linkUrl: "/poster/bakewell",
  description: "no description"
};
const erf = {
  name: "erf",
  title: "ERF",
  mediumUrl: "/images/posters/erf" + posterMedium,
  thumbUrl: "/images/posters/erf-thumb" + posterThumb,
  linkUrl: "/poster/erf",
  description: "no description"
};

const foden = {
  name: "foden",
  title: "Foden",
  mediumUrl: "/images/posters/foden" + posterMedium,
  thumbUrl: "/images/posters/foden-thumb" + posterThumb,
  linkUrl: "/poster/foden",
  description: "no description"
};
const rhosneigr = {
  name: "rhosneigr",
  title: "Rhosneigr",
  mediumUrl: "/images/posters/rhosneigr" + posterMedium,
  thumbUrl: "/images/posters/rhosneigr-thumb" + posterThumb,
  linkUrl: "/poster/rhosneigr",
  description: "no description"
};
const chatsworth = {
  name: "chatsworth",
  title: "Chatsworth Hall",
  mediumUrl: "/images/posters/chatsworth" + posterMedium,
  thumbUrl: "/images/posters/chatsworth-thumb" + posterThumb,
  linkUrl: "/poster/chatsworth",
  description: "no description"
};
const littleMoretonHall = {
  name: "littleMoretonHall",
  title: "Little Moreton Hall",
  mediumUrl: "/images/posters/littleMoretonHall" + posterMedium,
  thumbUrl: "/images/posters/littleMoretonHall-thumb" + posterThumb,
  linkUrl: "/poster/littleMoretonHall",
  description: "no description"
};
const bramallHall = {
  name: "bramallHall",
  title: "Bramall Hall",
  mediumUrl: "/images/posters/bramallHall" + posterMedium,
  thumbUrl: "/images/posters/bramallHall-thumb" + posterThumb,
  linkUrl: "/poster/bramallHall",
  description: "no description"
};
const stephensonsRocket = {
  name: "stephensonsRocket",
  title: "Stephenson's Rocket",
  mediumUrl: "/images/posters/stephensonsRocket" + posterMedium,
  thumbUrl: "/images/posters/stephensonsRocket-thumb" + posterThumb,
  linkUrl: "/poster/stephensonsRocket",
  description: ""
};
const spitfire = {
  name: "spitfire",
  title: "Spitfire",
  mediumUrl: "/images/posters/spitfire" + posterMedium,
  thumbUrl: "/images/posters/spitfire-thumb" + posterThumb,
  linkUrl: "/poster/spitfire",
  description: "no description"
};
const redArrows = {
  name: "redArrows",
  title: "Red Arrows",
  mediumUrl: "/images/posters/redArrows" + posterMedium,
  thumbUrl: "/images/posters/redArrows-thumb" + posterThumb,
  linkUrl: "/poster/redArrows",
  description: "no description"
};
const aberffraw = {
  name: "aberffraw",
  title: "Aberffraw",
  mediumUrl: "/images/posters/aberffraw" + posterMedium,
  thumbUrl: "/images/posters/aberffraw-thumb" + posterThumb,
  linkUrl: "/poster/aberffraw",
  description: "no description"
};
const trearddurBay = {
  name: "trearddurBay",
  title: "Trearddur Bay",
  mediumUrl: "/images/posters/trearddurBay" + posterMedium,
  thumbUrl: "/images/posters/trearddurBay-thumb" + posterThumb,
  linkUrl: "/poster/trearddurBay",
  description: "no description"
};
const southStack = {
  name: "southStack",
  title: "SouthStack Lighthouse",
  mediumUrl: "/images/posters/southStack" + posterMedium,
  thumbUrl: "/images/posters/southStack-thumb" + posterThumb,
  linkUrl: "/poster/southStack",
  description: "no description"
};
const southStackPuffin = {
  name: "southStackPuffin",
  title: "South Stack Puffin",
  mediumUrl: "/images/posters/southStackPuffin" + posterMedium,
  thumbUrl: "/images/posters/southStackPuffin-thumb" + posterThumb,
  linkUrl: "/poster/southStackPuffin",
  description: "no description"
};
const twrMawrLighthouse = {
  name: "twrMawrLighthouse",
  title: "Twr Mawr Lighthouse ",
  mediumUrl: "/images/posters/twrMawrLighthouse" + posterMedium,
  thumbUrl: "/images/posters/twrMawrLighthouse-thumb" + posterThumb,
  linkUrl: "/poster/twrMawrLighthouse",
  description: "no description"
};
const theCloud = {
  name: "theCloud",
  title: "The Cloud ",
  mediumUrl: "/images/posters/theCloud" + posterMedium,
  thumbUrl: "/images/posters/theCloud-thumb" + posterThumb,
  linkUrl: "/poster/theCloud",
  description: "no description"
};

/*
Poster Collection

*/

export const posters = [
  alderleyEdge,
  lymeCage,
  mowCop,
  jodrellBank,
  beestonCastle,
  twemlowViaduct,
  sandbach,
  holmesChapel,
  bakewell,
  erf,
  foden,
  rhosneigr,
  chatsworth,
  littleMoretonHall,
  bramallHall,
  stephensonsRocket,
  spitfire,
  redArrows,
  aberffraw,
  trearddurBay,
  southStack,
  southStackPuffin,
  twrMawrLighthouse,
  theCloud
];

/*
Sections

Defines the available genre/category of posters

The name used must be exactly the same (case matters too) 
as the name of the image file.

If creating a new section you also need to define what posters
are related to the section 

*/

const countryside = {
  name: "Countryside",
  title: "Countryside",
  thumbUrl: "/images/sections/countryside" + sectionThumb,
  linkUrl: "/gallery/Countryside",
  posters: [
    lymeCage,
    alderleyEdge,
    mowCop,
    jodrellBank,
    beestonCastle,
    theCloud,
    twemlowViaduct
  ],
  description: "A description for the country side"
};
const townsAndVillages = {
  name: "TownsAndVillages",
  title: "Towns & Villages",
  thumbUrl: "/images/sections/townsAndVillages" + sectionThumb,
  linkUrl: "/gallery/TownsAndVillages",
  posters: [sandbach, holmesChapel, bakewell, erf, foden, rhosneigr],
  description: "A description for Towns and Villages"
};
const heritage = {
  name: "Heritage",
  title: "Heritage",
  thumbUrl: "/images/sections/heritage" + sectionThumb,
  linkUrl: "/gallery/Heritage",
  posters: [
    chatsworth,
    littleMoretonHall,
    bramallHall,
    beestonCastle,
    jodrellBank,
    mowCop
  ],
  description: "A description for Heritage"
};
const seaRiversLakes = {
  name: "SeaRiversLakes",
  title: "Sea, Rivers & Lakes",
  thumbUrl: "/images/sections/seaRiversLakes" + sectionThumb,
  linkUrl: "/gallery/SeaRiversLakes",
  posters: [
    rhosneigr,
    aberffraw,
    trearddurBay,
    southStack,
    southStackPuffin,
    twrMawrLighthouse
  ],
  description: "A description for Sea River and Lakes"
};
const trainsPlanesAndAutomobiles = {
  name: "TrainsPlanesAndAutomobiles",
  title: "Trains,Planes & Automobiles",
  thumbUrl: "/images/sections/trainsPlanesAndAutomobiles" + sectionThumb,
  linkUrl: "/gallery/TrainsPlanesAndAutomobiles",
  posters: [twemlowViaduct, stephensonsRocket, erf, foden, spitfire, redArrows],
  description: "A description for Trains Planes and Automobiles"
};
const personalisedCards = {
  name: "PersonalisedCards",
  title: "Personalised Cards",
  thumbUrl: "/images/sections/personalisedCards" + sectionThumb,
  linkUrl: "/gallery/PersonalisedCards",
  posters: [],
  description: ""
};

export const sections = [
  countryside,
  townsAndVillages,
  heritage,
  seaRiversLakes,
  trainsPlanesAndAutomobiles,
  personalisedCards
];
