import React from "react";
import { GalleryDetail, GalleryImage } from ".";

interface Props {}

export const GalleryPage: React.FC<Props> = () => {
  return (
    <>
      <GalleryImage />
      <GalleryDetail />
    </>
  );
};
