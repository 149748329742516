import React from "react";
import { SectionDetail, SectionImage } from ".";

interface Props {}

export const SectionPage: React.FC<Props> = () => {
  return (
    <>
      <SectionImage />
      <SectionDetail />
    </>
  );
};
