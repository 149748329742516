import React from "react";
import { useStyles } from "./GalleryMenu.styles";
import { MenuItem, Menu } from "@material-ui/core";
import { Link } from "react-router-dom";
import { sections } from "../../Constants";

// GalleryMenu passes through the className prop to the tooltip child.

// A lot going on here to provide a smooth "unconscious expectation" UI workflow
// 1. present gallery menu after a short delay if mouse is still on Gallery after that delay
// 2. remove gallery menu after a short delay if mouse has not moved over the menu items
// 3. remove gallery menu if mouse moved over menu items but is moved away without making a selection
// 4. clicking on gallery prevents menu from appearing
// minimise the "hot area" for mouse entering gallery menu (restricted to the actual letters of the word)

interface Props {
  className?: any;
}

export const GalleryMenu: React.FC<Props> = ({ className }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // used to delay the menu opening to allow user to click on navigation
  // without menu appearing underneat to interrupt user workflow
  const [openTimeout, setOpenTimeout] = React.useState<null | any>(null);
  // used to close the menu if mouse not moved into/over menuItems
  const [closeTimeout, setCloseTimeout] = React.useState<null | any>(null);

  const classes = useStyles();

  function handleGalleryClick() {
    // user has clicked on gallery, not on menu item's, so prevent menu items from
    // appearing if the click has occurred before the menu has appeared.
    clearTimeout(openTimeout);
    // Now do the behaviour
  }
  function handleMenuItemClicked(item: any) {
    return (event: any) => {
      closeMenu();
    };
  }
  function closeMenu() {
    setAnchorEl(null);
  }

  function mouseEntersGallery(event: any) {
    // delay opening to allow user to simply click on "gallery"
    // store callback's id so it can be cancelled if mouse leaves
    const target = event.currentTarget;
    setOpenTimeout(setTimeout(() => setAnchorEl(target), 1100));
  }

  function mouseLeavesGallery() {
    // if mouse leaves the Gallery item
    // do not open menu (by removing the open menu cb )
    clearTimeout(openTimeout);
  }

  function mouseEntersMenu() {
    // if mouse moved into menu, do not auto close it
    clearTimeout(closeTimeout);
  }
  function mouseLeavesMenu() {
    // close the menu when the mouse leaves without menu item selection
    setAnchorEl(null);
  }

  function handleMenuOpen() {
    // set up auto close to occur after short delay
    // will be cancelled if menu is entered
    setCloseTimeout(setTimeout(() => closeMenu(), 900));
  }

  return (
    <>
      <div className={className}>
        <Link to="/gallery">
          <div
            onMouseEnter={mouseEntersGallery}
            onMouseLeave={mouseLeavesGallery}
            onClick={handleGalleryClick}
          >
            GALLERY
          </div>
        </Link>
      </div>

      <Menu
        className={classes.menu}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onEnter={handleMenuOpen}
      >
        <div onMouseLeave={mouseLeavesMenu} onMouseEnter={mouseEntersMenu}>
          <Link to="/gallery">
            <MenuItem
              className={classes.menuItem}
              onClick={handleMenuItemClicked("all")}
            >
              All
            </MenuItem>
          </Link>
          {sections.map((section, index) => {
            return (
              <Link key={index} to={section.linkUrl}>
                <MenuItem
                  className={classes.menuItem}
                  onClick={handleMenuItemClicked(section)}
                >
                  {section.title}
                </MenuItem>
              </Link>
            );
          })}
        </div>
      </Menu>
    </>
  );
};
