import React from "react";
import { useStyles } from "./GalleryNavigator.styles";
import { sections } from "../../Constants";
import { ThumbsContainer } from "../ThumbsContainer/ThumbsContainer";

interface Props {}

export const GalleryNavigator: React.FC<Props> = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ThumbsContainer thumbs={sections} />
    </div>
  );
};
