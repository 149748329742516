import React from "react";
import { useStyles } from "./SiteNavigationBar.styles";
import { Link } from "react-router-dom";
import { GalleryMenu } from "../GalleryMenu/GalleryMenu";

interface Props {}

export const SiteNavigationBar: React.FC<Props> = () => {
  const { site_navigation_bar_root, options, option, dot } = useStyles();

  return (
    <div className={site_navigation_bar_root}>
      <div className={options}>
        <Link className={option} to="/">
          HOME
        </Link>
        <div className={dot}></div>
        <GalleryMenu className={option} />
        <div className={dot}></div>
        <Link className={option} to="/about">
          ABOUT
        </Link>
        <div className={dot}></div>
        <Link className={option} to="/contact">
          CONTACT&nbsp;ME
        </Link>
        <div className={dot}></div>
        <Link className={option} to="/basket">
          BASKET
        </Link>
      </div>
    </div>
  );
};
