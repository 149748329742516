import React from "react";
import { useStyles } from "./PosterImage.styles";
import { PosterCarousel } from "../../Components";
interface Props {
  poster?: any;
}

export const PosterImage: React.FC<Props> = props => {
  const posters = [];
  posters.push(props.poster);
  const classes = useStyles();
  return (
    <div className={classes.posterImage_root}>
      <PosterCarousel posters={posters} />
    </div>
  );
};
