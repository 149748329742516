import "./bem-overrides.css";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    gridContainer: {
      display: "grid",
      gridTemplateColumns:
        "[left] 70px [sliderStart] auto [sliderEnd] 70px [right]",
      gridTemplateRows: "[top] auto [middle] 50px [bottom]"
    },
    gridItem: {
      gridColumnStart: "left",
      gridColumnEnd: "right",
      gridRowStart: "top",
      gridRowEnd: "bottom",
      zIndex: 10,
      objectFit: "cover",
      height: "calc(100vh - 10px)"
    },
    backButton: {
      borderRadius: "50%",
      background: "transparent no-repeat",
      border: "2px solid white",
      outline: "none",
      height: "50px",
      width: "50px",
      marginLeft: "10px",
      alignSelf: "center",
      gridColumnStart: "left",
      gridRowStart: "top",
      gridRowEnd: "bottom",
      zIndex: 11
    },
    nextButton: {
      borderRadius: "50%",
      background: "transparent no-repeat",
      border: "2px solid white",
      outline: "none",
      height: "50px",
      width: "50px",
      alignSelf: "center",
      marginLeft: "10px",
      gridColumnStart: "sliderEnd",
      gridRowStart: "top",
      gridRowEnd: "bottom",
      zIndex: 11
    },
    dotGroup: {
      gridColumnStart: "sliderStart",
      gridColumnEnd: "sliderEnd",
      gridRowStart: "middle",
      gridRowEnd: "bottom",
      zIndex: 11,
      alignSelf: "center",
      justifySelf: "center"
    },

    cover: {
      height: "calc(100vh - 10px)"
    }
  });
});
