import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { styleConstant } from "../../Constants";

export const useStyles = makeStyles((theme: Theme) => {
  const { titleComponentHeight } = styleConstant;
  return createStyles({
    titleContainer: {
      height: titleComponentHeight,
      gridColumnStart: "left",
      gridRowStart: "top",
      zIndex: 1,
      display: "flex",
      justifyContent: "center",
      justifyItems: "center"
    },
    title: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      justifyItems: "center"
    }
  });
});
