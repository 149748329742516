import React from "react";
import { useStyles } from "./GalleryDetail.styles";
import { posters } from "../../Constants";
import { ThumbsContainer } from "../../Components";

interface Props {}

export const GalleryDetail: React.FC<Props> = () => {
  const classes = useStyles();

  return (
    <div className={classes.galleryDetail_root}>
      <ThumbsContainer thumbs={posters} />
    </div>
  );
};
