import React from "react";
import { useStyles } from "./HomePageDetail.styles";
import { GalleryNavigator } from "../../Components/GalleryNavigator/GalleryNavigator";

interface Props {}

export const HomePageDetail: React.FC<Props> = () => {
  const classes = useStyles();
  return (
    <div className={classes.homePageDetail_root}>
      <GalleryNavigator />
    </div>
  );
};
