import React from "react";
import { BasketDetail, BasketImage } from ".";

interface Props {}

export const BasketPage: React.FC<Props> = () => {
  return (
    <>
      <BasketImage />
      <BasketDetail />
    </>
  );
};
