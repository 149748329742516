import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    aboutImage_root: {
      gridColumnStart: "left",
      gridRowStart: "top",
      gridRowEnd: "bottom",
      zIndex: 0
    },
    content: {
      width: "100%",
      height: "100%",
      objectFit: "cover"
    }
  });
});
