import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
// todo style this component without depending on !important

export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    menu: {
      marginTop: "35px"
    },
    menuItem: {
      fontSize: "1rem !important",
      padding: "0 ",
      margin: "0 !important",
      minHeight: "30px !important"
    }
  });
});
