import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { SiteNavigationBar } from "./Components/SiteNavigationBar";
import { Title } from "./Components/Title";
import { styleConstant } from "./Constants";
import { Switch, Route } from "react-router";
import {
  HomePage,
  SectionPage,
  PosterPage,
  GalleryPage,
  AboutPage,
  ContactPage,
  BasketPage
} from "./Pages";

const useStyles = makeStyles((theme: Theme) => {
  const { siteNavigationComponentHeight } = styleConstant;
  return createStyles({
    appRoot: {
      padding: 0,
      display: "grid",
      gridTemplateColumns: "[left] 1fr [center] minmax(600px,33%) [right]",
      gridTemplateRows: `[top] ${siteNavigationComponentHeight} [header-end] auto [bottom]`,
      height: "calc(100vh - 10px)",
      width: "100%"
    }
  });
});

export const App: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.appRoot} data-testid="app-container">
      <Title />
      <SiteNavigationBar />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/gallery/:section" component={SectionPage} />
        <Route exact path="/poster/:id" component={PosterPage} />
        <Route exact path="/gallery" component={GalleryPage} />
        <Route exact path="/about" component={AboutPage} />
        <Route exact path="/contact" component={ContactPage} />
        <Route exact path="/basket" component={BasketPage} />
      </Switch>
    </div>
  );
};
