/*

Display Section or Poster thumb prints, 
Support navigation to Section or Poster Detail

*/

import React from "react";
import { useStyles } from "./ThumbsContainer.styles";
import { IThumb } from "../../Interfaces/interfaces";
import { Link } from "react-router-dom";

interface Props {
  thumbs: IThumb[];
}

export const ThumbsContainer: React.FC<Props> = props => {
  const classes = useStyles();

  const renderThumb = (thumb: any, index: number) => {
    return (
      <Link key={index} className={classes.thumbs} to={thumb.linkUrl}>
        <img src={process.env.PUBLIC_URL + thumb.thumbUrl} alt={thumb.name} />
      </Link>
    );
  };

  return (
    <div className={classes.thumbContainer}>
      {props.thumbs.map((thumb, index) => {
        return renderThumb(thumb, index);
      })}
    </div>
  );
};
